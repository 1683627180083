import Collapse from '@components/ui/collapse'
import React, { useContext, useEffect, useState } from 'react'
import { xml2json } from 'xml-js'
import { classStyle } from '../__index'
import { joinClasses } from '@utils/styles'
import Text from '@components/ui/text'
import { FormData } from '../__index'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'

const Requests = () => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const [requestData, setRequestData] = useState<FormData | null>(null)

	useEffect(() => {
		// Fetch or load your XML file data here
		fetch('/data/form1.xml')
			.then((response) => response.text())
			.then((xmlData) => {
				const options = { compact: true, spaces: 4 } // XML to JSON options
				const jsonData = JSON.parse(xml2json(xmlData, options))
				setRequestData(jsonData)
			})
			.catch((error) => console.error('Error fetching XML data:', error))
	}, [])

	//   const getSubThemes = (subTheme: string, key: number) => {
	//     const title = theme?.requestSubThemes.find(
	//         (subThm) => subThm.name == subTheme
	//     )?.title
	//     return (
	//         <span key={`${subTheme}-${key}`} className={pageUtils.classes.category}>
	//             {title}
	//         </span>
	//     )
	// }

	const renderRequestCard = () => {
		if (!requestData) {
			return <div>Loading...</div>
		}
		const request = pageData.requests.find(
			(request) => request.requestId === requestData.body.request._attributes.id
		)

		return (
			<div key={'request.requestId'} className={classStyle.accordion}>
				<Collapse
					id={0}
					buttonText={
						<>
							<div>
								<span className={classStyle.btnTitle}>{request?.title}</span>
								{/* <div className={classStyle.tag}>
									{request?.subThemes.map(getSubThemes)}
								</div> */}
							</div>
						</>
					}
				>
					<div
						className={joinClasses([classStyle.textContent, classStyle.panel])}
					>
						<Text content={request?.description} />
						<button
							// onClick={onNavigateToRequest(request.requestId)}
							className={classStyle.btn}
						>
							{/* { pageData?.assets[requestInfor.button._text]} */}
							button
						</button>
					</div>
				</Collapse>
			</div>
		)
	}

	return renderRequestCard()
}

export default Requests
